import Markdown from 'marked-react';
import { Homepage } from '@/typings/types';
import Link from 'next/link';

type IProps = {
  intro: Homepage['header']['intro'];
};

const Intro = ({ intro }: IProps) => {
  return (
    <div
      className={
        'prose flex flex-col font-libertineRegular text-white prose-p:text-lg prose-p:font-normal prose-p:leading-6 prose-p:text-white prose-strong:font-bold prose-strong:text-white xl:w-fit 3xl:min-w-fit 3xl:prose-p:text-2xl'
      }
    >
      <Markdown>{intro}</Markdown>
      <Link
        className={'w-fit no-underline bg-caramel py-2 px-4 mt-5 text-xl text-white'}
        href={'/qrcode'}
      >
        Découvrir notre menu
      </Link>
    </div>
  );
};

export default Intro;
